var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-text-field',{staticClass:"flex-grow-0",attrs:{"rounded":"","dense":"","outlined":"","hide-details":"","label":"搜索","placeholder":"按商户名、订单号搜索","append-icon":_vm.icons.mdiMagnify},on:{"click:append":_vm.getDesserts},model:{value:(_vm.options.search),callback:function ($$v) {_vm.$set(_vm.options, "search", $$v)},expression:"options.search"}}),_c('v-spacer')],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.desserts,"items-per-page":_vm.options.pageSize,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"page":_vm.options.page,"server-items-length":_vm.totalDesserts,"loading":_vm.loading,"footer-props":{itemsPerPageOptions: [10, 15, 20, 50, 100]}},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.options, "pageSize", $event)},"update:items-per-page":[function($event){return _vm.$set(_vm.options, "pageSize", $event)},_vm.getDesserts],"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":[function($event){_vm.sortBy=$event},_vm.getDesserts],"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":[function($event){_vm.sortDesc=$event},_vm.getDesserts],"update:page":[function($event){return _vm.$set(_vm.options, "page", $event)},_vm.getDesserts]},scopedSlots:_vm._u([{key:"item.order_no",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'orderDetail', params: { id: item.id }}}},[_vm._v(" "+_vm._s(item.order_no)+" ")])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("amountFormat")(item.total))+" ")]}},{key:"item.payment_bill",fn:function(ref){
var item = ref.item;
return [(item.payment_bill)?_c('v-img',{staticStyle:{"cursor":"pointer"},attrs:{"src":item.payment_bill,"max-height":"32","max-width":"32"},on:{"click":function($event){return _vm.previewImage(item)}}}):_c('span',[_vm._v("未上传凭证")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.created_at))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.statusMap[item.status].color,"label":"","small":""}},[_vm._v(" "+_vm._s(_vm.statusMap[item.status].label)+" ")])]}}])})],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" 确定要删除这个订单吗? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":_vm.cancelDelete}},[_vm._v(" 取消 ")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.deleteItem}},[_vm._v(" 确认 ")]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
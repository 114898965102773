<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-card>
      <v-toolbar
        flat
        color="transparent"
      >
        <v-text-field
          v-model="options.search"
          rounded
          dense
          outlined
          hide-details
          label="搜索"
          placeholder="按商户名、订单号搜索"
          :append-icon="icons.mdiMagnify"
          class="flex-grow-0"
          @click:append="getDesserts"
        />
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :items-per-page.sync="options.pageSize"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :page.sync="options.page"
        :server-items-length="totalDesserts"
        :loading="loading"
        :footer-props="{itemsPerPageOptions: [10, 15, 20, 50, 100]}"
        @update:items-per-page="getDesserts"
        @update:page="getDesserts"
        @update:sort-by="getDesserts"
        @update:sort-desc="getDesserts"
      >
        <template
          #item.order_no="{ item }"
          v-permission="['90001']"
        >
          <router-link :to="{ name: 'orderDetail', params: { id: item.id }}">
            {{ item.order_no }}
          </router-link>
        </template>
        <template #item.total="{ item }">
          <span>{{ item.total | amountFormat }}</span>
        </template>
        <template #item.payment_bill="{ item }">
          <v-img
            v-if="item.payment_bill"
            :src="item.payment_bill"
            max-height="32"
            max-width="32"
            style="cursor: pointer"
            @click="previewImage(item)"
          ></v-img>
          <span v-else>未上传凭证</span>
        </template>
        <template #item.total="{ item }">
          {{ item.total | amountFormat }}
        </template>
        <template #item.created_at="{ item }">
          {{ item.created_at | dateFormat }}
        </template>
        <template #item.status="{ item }">
          <v-chip
            :color="statusMap[item.status].color"
            label
            small
          >
            {{ statusMap[item.status].label }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="deleteDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          确定要删除这个订单吗?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="cancelDelete"
          >
            取消
          </v-btn>
          <v-btn
            color="success"
            @click="deleteItem"
          >
            确认
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  getOrders, deleteOrder,
} from '@/api/order'
import {
  mdiMagnify, mdiClose, mdiDelete, mdiArrowRightCircle, mdiCalendar, mdiPencil,
} from '@mdi/js'

export default {
  name: 'Orders',
  data() {
    return {
      desserts: [],
      orderStatus: [
        {
          text: '待付款',
          value: 1,
        },
        {
          text: '待审核',
          value: 2,
        },
        {
          text: '待发货',
          value: 3,
        },
        {
          text: '部分发货',
          value: 4,
        },
        {
          text: '已发货',
          value: 5,
        },
        {
          text: '已完成',
          value: 6,
        },
        {
          text: '已取消',
          value: 7,
        },
        {
          text: '已退款',
          value: 8,
        },
      ],
      statusMap: {
        1: {
          label: '待付款',
          color: 'warning',
        },
        2: {
          label: '待审核',
          color: 'primary',
        },
        3: {
          label: '待发货',
          color: '#03A9F4',
        },
        4: {
          label: '部分发货',
          color: '#00BCD4',
        },
        5: {
          label: '已发货',
          color: '#009688',
        },
        6: {
          label: '已完成',
          color: 'success',
        },
        7: {
          label: '已取消',
          color: 'black',
        },
        8: {
          label: '已退款',
          color: 'error',
        },
      },
      totalDesserts: 0,
      loading: true,
      deleteDialog: false,
      headers: [
        { text: '#ID', value: 'order_no' },
        { text: '商户', value: 'merchant_name' },
        { text: '产品数量', value: 'qty' },
        { text: '总金额', value: 'total' },
        { text: '凭证', value: 'payment_bill', sortable: false },
        { text: '下单时间', value: 'created_at' },
        { text: '状态', value: 'status' },
        {
          text: '操作',
          align: 'center',
          value: 'actions',
          sortable: false,
        },
      ],
      sortFieldsMap: {
        merchant_name: 'merchant__name',
      },
      icons: {
        mdiMagnify,
        mdiClose,
        mdiDelete,
        mdiArrowRightCircle,
        mdiCalendar,
        mdiPencil,
      },
      options: {
        page: 1,
        pageSize: 10,
        search: '',
      },
      sortBy: '',
      sortDesc: false,
    }
  },

  computed: {
    filterOptions() {
      if (this.sortBy) {
        const sort = this.sortFieldsMap[this.sortBy] || this.sortBy
        const ordering = this.sortDesc ? `-${sort}` : `${sort}`
        return {
          ordering,
          ...this.options,
        }
      }
      return this.options
    },
  },

  created() {
    this.getDesserts()
  },

  methods: {
    getDesserts() {
      this.loading = true
      if (this.sortDesc === undefined) {
        return
      }
      getOrders(this.filterOptions).then(response => {
        this.desserts = response.data.results
        this.totalDesserts = response.data.count
        this.loading = false
      })
    },
    previewImage(item) {
      this.$viewerApi({
        images: [item.payment_bill],
        options: {
          toolbar: false,
          navbar: false,
          title: false,
          movable: false,
        },
      })
    },
    deleteBtnClick(item) {
      this.rowId = item.id
      this.deleteDialog = true
    },
    cancelDelete() {
      this.rowId = null
      this.deleteDialog = false
    },
    async deleteItem() {
      await deleteOrder(this.rowId)
      this.rowId = null
      this.deleteDialog = false
      this.$toast.success('删除成功')
      this.options.page = 1
      this.getDesserts()
    },
  },
}
</script>
